<template>
  <v-container>
    <v-row no-gutters style="height: 100vh;">
      <v-col :align-self="'center'" justify-center class="text-center">
        <div class="text-vertical-center text-center secondary--text">
          <v-img
            alt="logo Al'"
            width="300px"
            style="margin: 0 auto;"
            src="/logo/logo.png"
            class="mb-12"
          />
          <h1 class="font-weight-bold">
            Il servizio non &egrave; al momento disponibile
          </h1>
          <h3>Ci scusiamo per il disagio!</h3>
          <h4>Ti invitiamo a ricaricare la pagina tra qualche minuto.</h4>
          <h3 class="font-weight-bold mt-5">
            Non puoi aspettare? contattaci al numero 049 8692211
          </h3>
          <v-btn
            color="primary"
            class="mt-5 rounded-xl secondary--text"
            x-large
            depressed
            :to="{ name: 'Home' }"
          >
            Ricarica la Homepage
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ServiceDown"
};
</script>
<style lang="scss">
html,
body {
  width: 100% !important;
  height: 100% !important;
}
body {
  margin: 0 !important;
}
.service-down {
  display: table;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 0px !important;
  max-width: 100% !important;
  .text-vertical-center {
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .text-vertical-center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 18px 0;
    font: 900 35px;
  }
  .text-vertical-center h3 {
    margin: 0;
    padding: 5px 0;
    font: 800 22px;
  }
  .text-vertical-center h4 {
    margin: 0;
    padding: 0 0 30px 0;
    font: 400 20px;
  }
  .text-vertical-center h5 {
    margin: 0;
    padding: 0;
    color: $primary;
    margin: 0;
    padding: 30px 0 5px 0;
    font: 900 18px;
    text-transform: uppercase;
  }
}
</style>
